<!--
 * @Module  搜索页
 -->

<template>
  <div>
    <!-- PC端展示内容 -->
    <div v-if="isPc" class="search">
      <!-- <img class="search_banner" src="~@/assets/images/pc/banner_search.png" alt="" /> -->

      <div class="search_box">
        <div class="location">
          <div class="location_title">搜索结果</div>
        </div>

        <div class="search_content">
          <div
            class="list_news-item"
            v-for="(item, index) in mainLists"
            :key="index"
            @click="clickRow(item)"
          >
            <div class="list_dot"></div>

            <div class="item_info">
              <div class="info_title" v-html="item.contentTitle"></div>
              <!-- <div class="info_intro">
                {{ item.contentDescription }}
              </div> -->
            </div>

            <div class="info_date">{{ getDateTime(item.contentTime) }}</div>
          </div>
        </div>

        <div class="search_content_page" v-if="mainLists.length">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- wap端展示内容 -->
    <div v-else class="search-wap">
      <div class="search_box">
        <div class="location">
          <div class="location_title">搜索结果</div>
        </div>

        <div class="search_content">
          <div class="search_content_result">
            <div>
              关键词：
              <span style="color: #0e59ae">{{ search }}</span>
            </div>
            <div>【共有{{ total }}项查询结果】</div>
          </div>
          <div
            class="list_news-item"
            v-for="(item, index) in mainLists"
            :key="index"
            @click="clickRow(item)"
          >
            <div style="display: flex; align-items: center">
              <div class="list_dot"></div>
              <div class="item_info">
                <div class="info_title" v-html="item.contentTitle"></div>
              </div>
            </div>

            <div class="info_date">{{ getDateTime(item.contentTime) }}</div>
          </div>
        </div>

        <div class="search_content_page" v-if="mainLists.length">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :pager-count="5"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<!--JavaScript-->
<script>
import { mapState } from 'vuex'
import { dateObj } from '@/utils/index'
import Pager from '@/components/pager/index'
export default {
  name: 'MachineSearch',
  data() {
    return {
      search: '',
      mainLists: [],
      pageSize: 10,
      currentPage: 1,
      total: 0
    }
  },
  computed: {
    ...mapState(['isPc'])
  },
  created() {
    // 获取列表数据
    this.getListData()
  },
  methods: {
    /**
     * 获取列表数据
     */
    async getListData() {
      const {
        query: { search }
      } = this.$route

      this.$root.eventBus.$emit('searchVal', {
        searchVal: search
      })

      this.search = search
      try {
        if (!search) {
          console.log('search is null')
          return
        }
        const { errCode, data } = await this.api.post('/webclient/search', {
          siteId: this.$siteId,
          content: search,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          orders: [{ column: 'contentTime', asc: false }]
        })
        if (!errCode) {
          this.mainLists = (data.records || []).filter(
            (item) => item.contentDisplay !== 'hidden'
          )
          this.total = data.total

          const reg = new RegExp(search, 'g')
          this.mainLists.map(
            (item) =>
              (item.contentTitle = item.contentTitle.replace(
                reg,
                `<span style="color: #409eff;">${search}</span>`
              ))
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    changeSearch(event) {
      this.$route.query.search = event.target.value
    },
    /**
     * 搜索结果点击
     */
    clickRow(row) {
      const { contentUrl, id = null } = row
      if (contentUrl) {
        // 外链
        window.open(contentUrl)
      } else {
        // 内链
        this.naviGate('detail', { t: new Date().getTime(), contentId: id })
      }
    },
    /**
     * 分页页数变化
     */
    handleCurrentChange(val) {
      this.currentPage = val
      this.getListData()
    },
    /**
     * @description: 时间格式转换 YYYY.MM.DD
     * @param {number} time 时间戳
     */
    getDateTime(time) {
      let date = dateObj(time)

      return date.year + '-' + date.month + '-' + date.day
    }
  },
  components: { Pager },
  watch: {
    $route(to, from) {
      // 获取列表数据
      this.getListData()
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 1000px;

  &_banner {
    width: 100%;
    height: 15.625vw;
  }

  .search_box {
    width: 1280px;
    margin: 0 auto;

    .location {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ecedf2;
      height: 50px;
      margin-top: 20px;

      &_title {
        position: relative;
        color: #333;
        font-size: 22px;
        font-weight: bold;

        &::before {
          position: absolute;
          left: 0;
          bottom: -11px;
          content: '';
          width: 100%;
          height: 2px;
          background: #0e59ae;
        }
      }
    }

    .search_content {
      width: 100%;

      .list_news-item {
        display: flex;
        align-items: center;
        margin-top: 28px;
        cursor: pointer;

        &:hover {
          .list_dot {
            background: #409eff;
          }

          .item_info {
            .info_title {
              color: #409eff;
            }
          }
        }

        .list_dot {
          width: 6px;
          height: 6px;
          background: #ececec;
          margin-right: 12px;
          border-radius: 100%;
        }

        .item_info {
          flex: 1;
          width: 0;

          .info_title {
            width: 100%;
            color: #333333;
            font-size: 16px;
            /* font-weight: bold; */
            @include ellipsis;
          }

          .info_intro {
            width: 100%;
            font-size: 14px;
            color: #777777;
            line-height: 20px;
            @include ellipsis;
          }
        }

        .info_date {
          font-size: 14px;
          color: #999999;
        }
      }
    }

    .search_content_page {
      text-align: center;
      margin: 50px 0;
    }
  }
}

.search-wap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  min-height: 300px;

  &_banner {
    width: 100%;
    height: 15.625vw;
  }

  .search_box {
    padding: 0 0.2rem;
    // width: 100%;
    // margin: 0 auto;

    .location {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ecedf2;
      height: 50px;
      // margin-top: 20px;

      &_title {
        position: relative;
        color: #004098;
        font-size: 0.18rem;
        font-weight: bold;

        &::before {
          position: absolute;
          left: 0;
          bottom: -13px;
          content: '';
          width: 100%;
          height: 2px;
          background: #004098;
        }
      }
    }

    .search_content {
      width: 100%;
      .search_content_result {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.1rem 0.1rem 0.08rem;
        background: #f5f8ff;
        color: #999999;
        font-size: 0.12rem;
        margin-top: 0.2rem;
      }
      .list_news-item {
        // margin-top: 28px;
        padding: 0.18rem 0 0.16rem;
        border-bottom: 1px solid #e2e2e2;
        .list_dot {
          width: 0.06rem;
          height: 0.06rem;
          background: #0e59ae;
          margin-right: 0.1rem;
          border-radius: 100%;
        }

        .item_info {
          flex: 1;
          width: 0;

          .info_title {
            width: 100%;
            color: #333333;
            font-size: 0.16rem;
            font-weight: 500;
            @include ellipsis;
          }

          .info_intro {
            width: 100%;
            font-size: 14px;
            color: #777777;
            line-height: 20px;
            @include ellipsis;
          }
        }

        .info_date {
          margin: 0.08rem 0 0 0.16rem;
          font-size: 0.14rem;
          color: #999999;
        }
      }
    }

    .search_content_page {
      text-align: center;
      margin: 50px 0;
    }
  }
}
</style>
