<!--
 * @Module wap端自定义分页器组件
 -->
<template>
  <div class="pager">
    <!-- 上一页 -->
    <div class="pager-left" @click="reducePage">
      <i
        :style="{ color: isLeftClick ? '#183878' : '#a4a4a4' }"
        class="el-icon-back"
      ></i>
    </div>
    <!-- 下一页 -->
    <div class="pager-right" @click="addPage">
      <i
        :style="{ color: isRightClick ? '#183878' : '#a4a4a4' }"
        class="el-icon-right"
      ></i>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'pager',
    data() {
      return {
        isLeftClick: false, // 左按钮是否可点击
        isRightClick: false, // 右按钮是否可点击
        pageRows: this.currentPage
      }
    },
    props: {
      total: {
        type: Number
      },
      currentPage: {
        type: Number
      },
      pageSize: {
        type: Number
      }
    },
    created() {
      this.distClick()
    },
    methods: {
      distClick() {
        // 左按钮是否可点击
        if (this.pageRows === 1) {
          this.isLeftClick = false
        } else {
          this.isLeftClick = true
        }
        // 右按钮是否可点击
        const page = Math.ceil(this.total / this.pageSize)
        if (page > 1 && page > this.pageRows) {
          this.isRightClick = true
        } else {
          this.isRightClick = false
        }
      },
      /**
       * 减少页数
       */
      reducePage() {
        if (this.isLeftClick) {
          this.pageRows--
          this.$emit('currentChange', this.pageRows)
          this.distClick()
        }
      },
      /**
       * 增加页数
       */
      addPage() {
        if (this.isRightClick) {
          this.pageRows++
          this.$emit('currentChange', this.pageRows)
          this.distClick()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pager {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.44rem;
      height: 0.32rem;
      color: #a4a4a4;
      font-size: 0.18rem;
      border: 0.01rem solid #e2e2e2;
    }

    .pager-left {
      border-radius: 0.05rem 0 0 0.05rem;
    }

    .pager-right {
      border-left: none;
      border-radius: 0 0.05rem 0.05rem 0;
    }
  }
</style>
